import React, {createContext, useContext, useEffect, useState} from 'react'
import apiClient from "../services/apiClient";
import { useQuery } from 'react-query'
import { auth } from '../utils/firebase'
import { signOut, onAuthStateChanged } from "firebase/auth";

const AuthContext = createContext(null)

export const AuthContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [ user, setUser ] = useState({})
  const [ games, setGames ] = useState([])

    const { refetch: fetchUserAuth } = useQuery(
      "fetchUserFromToken",
      async () => {
          return await apiClient.fetchUserFromToken();
      },
      {
          enabled: false,
          onSuccess: ({data}) => {
              setUser(data)
              setIsLoggedIn(true)
          }
      }
    );

    const { refetch: fetchGames } = useQuery(
      "fetchGames",
      async () => {
          return await apiClient.fetchAllGames();
      },
    {
        enabled: false,
        onSuccess: ({data}) => {
            setGames(data)
        }
      }
    );

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
          if (user) {
            setIsLoggedIn(true)

            //fetchGames()
            await fetchUserAuth();
          } else {
            setUser(null);
          }
      });

      return () => unsubscribe();
    }, [setUser, user, isLoggedIn])


    const handleLogout = async () => {
        await signOut(auth)
          .then(() => {
              setUser(null);
          })
          .catch(() => {});
    };

    const authValue = { user, setUser, handleLogout, isLoggedIn, games }
    return (
        <AuthContext.Provider value={authValue}>
            <>{children}</>
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => useContext(AuthContext)
