import React, {useState} from 'react';
import {Alert, Box, Button, Container, Grid, InputAdornment, Paper, Typography} from "@mui/material";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {BaseTextField} from "../../textfield/StyledTextField";
import {FormHelper, PasswordRule} from "components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {validatePasswordSecurity} from "../../../utils/utils";
import { auth } from '../../../utils/firebase'
import { createUserWithEmailAndPassword } from "firebase/auth";
import theme from "../../../theme";
import { useMutation } from 'react-query'
import apiClient from '../../../services/apiClient'

function Register() {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    const [errors, setErrors] = useState({msg: ""});
    const [pwdError, setPwdError] = useState(['min', 'uppercase', 'lowercase', 'digits', 'symbols'])
    const [pwdMatch, setPwdMatch] = useState(false)

    const { mutate: createUser } = useMutation(
      async (userParticipant) => {
          return await apiClient.registerUser(userParticipant).then(user => {
              if (user.error) throw new Error(user.error);
              return user;
          });
      },
      {
          onSuccess: (data) => {
              Swal.fire({
                  title: t('register.Completed'),
                  text: t('register.CompletedMsg'),
                  showCancelButton: true,
                  cancelButtonText: t('app.Close'),
                  cancelButtonColor: theme.palette.primary.dark,
                  confirmButtonText: t('register.Login'),
                  confirmButtonColor: theme.palette.primary.light,
                  reverseButtons: true,
                  timer: 2500
              }).then((result) => {
                  if (result.value) {
                      window.location.href = '/dashboard'
                  } else {
                      window.location.href = '/'
                  }
              })
          },
          onError: (error) => {
              setErrors({msg: 'auth.Error'})
          }
      }
    );

    const { control,getValues, handleSubmit } = useForm({
        reValidateMode: "onBlur"
    });
    const handleOnSubmit = (evt) => {
        let user = {
            ...evt,
        }
        if (pwdMatch && pwdError.length === 0) {
            setIsLoading(true)
            registerUser(user).then(r => {
                  setIsLoading(false)
              }
            )
        }else{
            setErrors((e) => ({...e, msg: "app.Error"}))
        }
    };

    const registerUser = async (user) => {
        const {firstName, lastName, email, password} = user;
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            let user = userCredential.user;
            user = {
                uid: user.uid,
                email,
                firstName,
                lastName
            }
            await createUser(user)
        } catch (error) {
            setErrors((e) => ({...e, msg: "auth.Error"}))
        }
    }

    const handleShowPassword = () => setShowPassword(!showPassword);

    return (
        <Container maxWidth="lg" sx={{pt:5, pb:5}}>
            <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} sx={{pt:5, pb:5}}>
                <Paper elevation={5} sx={{p:5}}>
                    <Grid
                        spacing={1}
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        {
                            errors?.msg !== "" &&
                            <Grid item xs={12} sx={{pt: 2}}>
                                <Alert severity="error">{t(errors.msg)}</Alert>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Typography variant="h5" align="left" sx={{mb:0}}>
                                {t('register.Title')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                control={control}
                                name="firstName"
                                defaultValue=""
                                rules={{
                                    required: true
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        fullWidth
                                        sx={{mt:0, mb:0}}
                                        variant="outlined"
                                        label={t('register.FirstName')}
                                        error={error !== undefined}
                                        helperText={error ? t(FormHelper.required[error.type]) : ""}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                control={control}
                                name="lastName"
                                defaultValue=""
                                rules={{
                                    required: true
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        fullWidth
                                        sx={{mt:0, mb:0}}
                                        variant="outlined"
                                        label={t('register.LastName')}
                                        error={error !== undefined}
                                        helperText={error ? t(FormHelper.required[error.type]) : ""}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                sx={{mt:0, mb:0}}
                                name="email"
                                defaultValue=""
                                rules={{
                                    required: true,
                                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        label={t('register.Email')}
                                        error={error !== undefined}
                                        helperText={error ? t(FormHelper.email[error.type]) : ""}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                control={control}
                                name="password"
                                defaultValue=""
                                rules={{
                                    required: true
                                }}
                                render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        sx={{mt:0, mb:0}}
                                        label={t('register.Password')}
                                        fullWidth
                                        onChange={({ target: { value } }) =>{
                                            onChange(value);
                                            setPwdError(validatePasswordSecurity(value))
                                        }}
                                        type={showPassword? 'text' : 'password'}
                                        variant="outlined"
                                        name="password"
                                        error={error !== undefined}
                                        helperText={error ? t(FormHelper.required[error.type]) : ""}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    onClick={handleShowPassword}
                                                    className="showPassword"
                                                    position="end">
                                                    {showPassword ? <VisibilityIcon sx={{cursor:'pointer'}} /> : <VisibilityOffIcon sx={{cursor:'pointer'}}/> }
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                control={control}
                                sx={{mt:0, mb:0}}
                                name="confirmPassword"
                                defaultValue=""
                                rules={{
                                    required: true
                                }}
                                render={({  field: { ref, onChange, ...field }, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        sx={{mt:0, mb:0}}
                                        label={t('register.PasswordConfirm')}
                                        fullWidth
                                        type={showPassword? 'text' : 'password'}
                                        variant="outlined"
                                        name="confirmPassword"
                                        error={error !== undefined}
                                        helperText={error ? t(FormHelper.required[error.type]) : ""}
                                        onChange={({ target: { value } }) =>{
                                            const { password } = getValues();
                                            onChange(value);
                                            setPwdMatch(password === value)
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    onClick={handleShowPassword}
                                                    className="showPassword"
                                                    position="end">
                                                    {showPassword ? <VisibilityIcon sx={{cursor:'pointer'}}/> : <VisibilityOffIcon sx={{cursor:'pointer'}}/> }
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordRule pwdRuleError={pwdError} pwdMatch={pwdMatch}/>
                        </Grid>
                        <Grid item xs={12} align="left">
                            <Typography variant="h5" align="left" sx={{mt:5}}>
                                {t('register.Consent')}
                            </Typography>
                            <Typography variant="body" align="left" sx={{mt:5, mb:5}}>
                                {t('register.ConsentText')}
                            </Typography>
                        </Grid>
                        {
                            errors?.msg !== "" &&
                            <Grid item xs={12} sx={{pt: 2}}>
                                <Alert severity="error">{t(errors.msg)}</Alert>
                            </Grid>
                        }
                        <Grid item xs={12} md={9}/>
                        <Grid item xs={12} sx={{pb:2}}>
                            <Button fullWidth variant="contained" color="primary" size="large" type="submit" disabled={isLoading}>
                                {t('register.SubmitBtn')}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
}

export default Register;
